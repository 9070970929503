import React, { useEffect } from 'react';
import { PreAuth } from '../../../_layouts/pre-auth';
import { Container, Grid } from '@mui/material';
import { LandingPageStyled, Banner } from '.';
import { Animate } from '../../../_components';
import { AboutApp, BehindTheNumbers } from '../_components';
import { MenteeBenefits, Spotlight } from '../mentee';
import { FeaturedMentors, MentorBenefits, WhyYouShouldBeMentor } from '../mentor';
import { useNavigate } from 'react-router-dom';
import { preAuthRoutes } from '../../../routes';

const LandingPage = () => {

    /** Initialize plugins and variables */
    let navigate = useNavigate();

    useEffect(() => {
        console.log('landing page');
        navigate(`${preAuthRoutes('mentor_register_auth_details').path}`);
    }, []);

    return (
        <PreAuth isheaderwhite='true' havebanner='true'>
            <LandingPageStyled>
                <Grid container spacing={5}>
                    <Grid item xs={12} >
                        <Animate animate='right-left'>
                            <Banner />
                        </Animate>
                    </Grid>
                    <Grid item xs={12} >
                        <Container>
                            <Grid container spacing={5}>
                                <Grid item xs={12} >
                                    <Animate >
                                        <MentorBenefits />
                                    </Animate>
                                </Grid>
                                <Grid item xs={12} >
                                    <Animate animate='right-left'>
                                        <WhyYouShouldBeMentor />
                                    </Animate>
                                </Grid>
                                <Grid item xs={12} >
                                    <Animate>
                                        <AboutApp />
                                    </Animate>
                                </Grid>
                                <Grid item xs={12} >
                                    <Animate animate='right-left'>
                                        <MenteeBenefits />
                                    </Animate>
                                </Grid>
                                <Grid item xs={12} >
                                    <Animate>
                                        <BehindTheNumbers />
                                    </Animate>
                                </Grid>
                                <Grid item xs={12} >
                                    <Animate animate='right-left'>
                                        <Spotlight />
                                    </Animate>
                                </Grid>
                                <Grid item xs={12} >
                                    <Animate>
                                        <FeaturedMentors />
                                    </Animate>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>
            </LandingPageStyled>
        </PreAuth>
    );
};

export { LandingPage };
