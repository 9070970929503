import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { cookieServices } from '../_helpers';
import { PostAuth } from '../_layouts/post-auth';
import { preAuthRoutes } from './PreAuthRoutes';


export const PrivateRoute = ({ ...props }) => {

    // Checking if the user has an access token.
    // If they do, it will render the PostAuth component.
    // If they don't, it will redirect them to the sign in page.
    return cookieServices.get('accessToken') ? <PostAuth><Outlet /></PostAuth> : <Navigate to={`${preAuthRoutes('mentor_pre_register').path}`} />;
};
