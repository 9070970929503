import { ErrorElement } from "../_components/layout/error-element";
import { SendResetPasswordLink, ResetPassword, LandingPage, Giveaways, AboutUs, ContactUs, MentorPreRegister, MentorRegisterAuthDetails, MentorRegisterBasicDetails, MentorRegisterCompleted, MentorSignIn, Faq, TermsAndConditions, MentorRegisterCheckout, MenteeLandingPage, PaidCollegeInternships, BecomePavePartner, MentorRegisterCheckoutPayment, BecomePavePartnerReferAndEarnForm, PaidCollegeInternshipsReferAndEarnForm } from "../app/pre-auth";
import { MenteeRegisterAuthDetails, MenteeRegisterBasicDetails, MenteeRegisterCheckout, MenteeRegisterCheckoutPayment, MenteeRegisterCompleted, MenteeSignIn } from "../app/pre-auth/mentee";
import { PrivacyAndPolicy } from "../app/pre-auth/privacy-and-policy";

/**
 * path: the URL path of the route
 * name: the name of the route, used for display purposes
 * element: the React component to be rendered when the route is accessed
 * layout: the layout component to be used for rendering the route
 * isMenuItem: a boolean flag to indicate whether the route should be displayed in a menu
 * menuIcon: the icon to be used for the route in the menu
 * module: the module that the route belongs to
 * isModuleMenuItem: a boolean flag to indicate whether the route should be displayed in the module's menu
 * isMainElement: a boolean flag to indicate whether the route should be displayed as the main content of the page
 * errorElement: the React component to be rendered when an error occurs while rendering the route
 * menuPermission: the permission required to access the route
 */

const allRoutes = {

    /** begin::landing module */
    'landing_page': {
        path: "/",
        name: "",
        element: <LandingPage />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    /** end::landing module */

    /** begin::giveaways module */
    // 'giveaways': {
    //     path: "/giveaways",
    //     name: "Giveaways",
    //     element: <Giveaways />,
    //     layout: "/pre-auth",
    //     isMainElement: true,
    //     isMenuItem: true,
    //     errorElement: <ErrorElement />
    // },
    /** end::giveaways module */

    /** begin::about_us module */
    'about_us': {
        path: "/about-pave",
        name: "About Pave",
        element: <AboutUs />,
        layout: "/pre-auth",
        isMainElement: true,
        isMenuItem: true,
        errorElement: <ErrorElement />
    },
    /** end::about_us module */

    /** begin::contact_us module */
    'contact_us': {
        path: "/contact-us",
        name: "Contact Us",
        element: <ContactUs />,
        layout: "/pre-auth",
        isMainElement: true,
        isMenuItem: true,
        errorElement: <ErrorElement />
    },
    /** end::contact_us module */

    /** begin::contact_us module */
    'faq': {
        path: "/faq",
        name: "FAQs",
        element: <Faq />,
        layout: "/pre-auth",
        isMainElement: true,
        isMenuItem: true,
        errorElement: <ErrorElement />
    },
    /** end::contact_us module */

    /** begin::contact_us module */
    'become_pave_partner': {
        path: "/become-a-pave-partner",
        name: "Become a Pave Partner",
        element: <BecomePavePartner />,
        layout: "/pre-auth",
        isMainElement: true,
        isMenuItem: true,
        errorElement: <ErrorElement />
    },
    'pave_partner_refer_and_earn': {
        path: "/refer-and-earn/pave-partner",
        name: "Refer and Earn",
        element: <BecomePavePartnerReferAndEarnForm />,
        layout: "/pre-auth",
        isMainElement: true,
        isMenuItem: true,
        errorElement: <ErrorElement />
    },
    /** end::contact_us module */

    /** begin::contact_us module */
    'paid_college_internships': {
        path: "/paid-college-internships",
        name: "Paid College Internships",
        element: <PaidCollegeInternships />,
        layout: "/pre-auth",
        isMainElement: true,
        isMenuItem: true,
        errorElement: <ErrorElement />
    },
    'paid_college_intern_refer_and_earn': {
        path: "/refer-and-earn/paid-college-intern",
        name: "Refer and Earn",
        element: <PaidCollegeInternshipsReferAndEarnForm />,
        layout: "/pre-auth",
        isMainElement: true,
        isMenuItem: true,
        errorElement: <ErrorElement />
    },
    /** end::contact_us module */

    /** begin::contact_us module */
    'terms_and_conditions': {
        path: "/terms-and-conditions",
        name: "Terms and Conditions",
        element: <TermsAndConditions />,
        layout: "/pre-auth",
        isMainElement: true,
        isMenuItem: true,
        errorElement: <ErrorElement />
    },
    'privacy_and_policy': {
        path: "/privacy-policy",
        name: "Privacy Policy",
        element: <PrivacyAndPolicy />,
        layout: "/pre-auth",
        isMainElement: true,
        isMenuItem: true,
        errorElement: <ErrorElement />
    },
    /** end::contact_us module */




    /** begin::forgot password module */
    'forgot_password': {
        path: "/password-recovery",
        name: "Forgot password?",
        element: <SendResetPasswordLink />,
        layout: "/pre-auth",
        isMainElement: true
    },
    'reset_mentor_password': {
        path: "/reset-password",
        name: "Reset password",
        element: <ResetPassword />,
        layout: "/pre-auth",
        isMainComponent: true
    },
    /** end::forgot password module */

    /** begin::mentor module */
    /** begin::sign in module */
    'mentor_sign_in': {
        path: "/mentor/sign-in",
        name: "Mentor Sign in",
        element: <MentorSignIn />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    /** end::sign in module */
    /** begin::mentor register module */
    'mentor_pre_register': {
        path: "/mentors/pre-register",
        name: "Create account",
        element: <MentorPreRegister />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    'mentor_register_auth_details': {
        path: "/mentors",
        // path: "/mentors/register/auth-details",
        name: "Auth Details",
        element: <MentorRegisterAuthDetails />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    'mentor_register_basic_details': {
        path: "/mentors/register/basic-details",
        name: "Basic Details",
        element: <MentorRegisterBasicDetails />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    'mentor_register_checkout': {
        path: "/mentors/register/checkout",
        name: "Checkout",
        element: <MentorRegisterCheckout />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    'mentor_checkout_payment': {
        path: "/mentors/register/checkout/payment",
        name: "Checkout",
        element: <MentorRegisterCheckoutPayment />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    'mentor_register_completed': {
        path: "/mentors/register/completed",
        name: "Registration completed",
        element: <MentorRegisterCompleted />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    /** end::mentor register module */
    /** end::mentor module */

    /** begin::mentee module */
    /** begin::sign in module */
    'mentee_sign_in': {
        path: "/athletes/sign-in",
        name: "Athlete Sign in",
        element: <MenteeSignIn />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    /** end::sign in module */
    'mentee_landing_page': {
        path: "/athletes/landing-page",
        // path: "/athletes",
        name: "Athlete",
        element: <MenteeLandingPage />,
        layout: "/pre-auth",
        isMainComponent: true
    },
    /** begin::mentee register module */
    'mentee_register_auth_details': {
        path: "/athletes",
        // path: "/athletes/register/auth-details",
        name: "Create account",
        element: <MenteeRegisterAuthDetails />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    'mentee_register_basic_details': {
        path: "/athletes/register/basic-details",
        name: "Basic Details",
        element: <MenteeRegisterBasicDetails />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    'mentee_register_checkout': {
        path: "/athletes/register/checkout",
        name: "Checkout",
        element: <MenteeRegisterCheckout />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    'mentee_checkout_payment': {
        path: "/athletes/register/checkout/payment",
        name: "Checkout",
        element: <MenteeRegisterCheckoutPayment />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    'mentee_register_completed': {
        path: "/athletes/register/completed",
        name: "Registration completed",
        element: <MenteeRegisterCompleted />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    /** end::mentee register module */
    /** end::mentee module */

};

/**
 * The function returns all routes or routes of a specific key type based on the input parameter.
 * @param {String} [keyType=null] - The `keyType` parameter is an optional parameter that specifies the type of
 * key to be used for authentication. If a `keyType` is provided, the function returns only the routes
 * that require that specific type of key for authentication. If `keyType` is not provided, the
 * function returns all
 * @returns {Object} The function `preAuthRoutes` is being returned. The function takes an optional parameter
 * `keyType` and returns either all routes or routes specific to the `keyType` provided. The
 * `allRoutes` variable is likely an object containing different routes for different types of users or
 * access levels.
 * @author Akshay N
 * @created_at 02/04/2023
 */
const preAuthRoutes = (keyType = null) => {
    return keyType ? allRoutes[keyType] : allRoutes;
};

/**
 * The function filters an array of route objects based on whether they are regular menu items or
 * module menu items.
 * @param [module=false] - The `module` parameter is an optional argument that is used to filter the
 * routes based on whether they are module menu items or regular menu items. If `module` is provided,
 * the function will only return routes that are module menu items and belong to the specified module.
 * If `module` is not
 * @returns an array of objects representing routes that have been filtered based on the value of the
 * `module` argument.
 */
const preAuthMenuRoutes = (module = false) => {
    /* The below code is a JavaScript function that filters an array of objects representing routes.
    The function takes an optional argument `module`, which is used to filter the routes based on
    whether they are module menu items or regular menu items. */
    return Object.values(allRoutes).filter(item => {
        return !module
            ? (item.isMenuItem === true)
            : (item.isModuleMenuItem === true && item.module === module)
                ? { ...item, path: item.path.split("/").pop() }
                : null;
    });
};

/**
 * The function `preAuthMenuBarRoutes` iterates over an object of routes, filters out routes that are
 * menu items, and organizes them into a nested structure based on parent-child relationships.
 * @returns an array of pre-authenticated menu bar routes.
 */
const preAuthMenuBarRoutes = () => {

    const preSignInRoutes = allRoutes;

    let preRoutes = [];

    // Iterating over the object.
    for (const key in preSignInRoutes) {

        // Checking if the object has the property.
        if (preSignInRoutes.hasOwnProperty(key)) {
            if (preSignInRoutes[key].isMenuItem) {

                // Checking if the route has a parent route.
                if (preSignInRoutes[key].parentRoute) {

                    // Finding the index of the parent route in the array of objects.
                    const parentRoute = preSignInRoutes[key].parentRoute;
                    var preRouteIndex = preRoutes.findIndex(function (route) {
                        return route.slug === parentRoute;
                    });

                    // Checking if the parent route has any children. If not, it is creating an empty array
                    // and pushing the child route into it. */
                    if (!preRoutes[preRouteIndex]['children'])
                        preRoutes[preRouteIndex]['children'] = [];

                    preRoutes[preRouteIndex]['children'].push({ ...preSignInRoutes[key], slug: key });

                } else
                    preRoutes.push({ ...preSignInRoutes[key], slug: key });
            }
        }
    }

    return preRoutes;
};

/**
 * This function returns the name of a pre element based on its path.
 * @param {String} path - The `path` parameter is a string representing the URL path of a route in a web application.
 * @returns {String} The function `getPreElementNameByPath` returns the name of the element associated with the
 * given path, which is obtained by searching for the path in the `allRoutes` object and returning the
 * corresponding `name` property. If no matching path is found, an empty string is returned.
 * @author Akshay N
 * @created_at 02/04/2023
 */
const getPreElementNameByPath = (path) => {
    if (path) {
        const elementItem = Object.values(allRoutes).find(item => item.path === path);
        return elementItem ? elementItem.name : '';
    }
};

export { preAuthRoutes, preAuthMenuRoutes, preAuthMenuBarRoutes, getPreElementNameByPath };